.current-cards{
    display: grid;
    // max-width: 80%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 3dvw;
    // grid-template-rows: 2dvh;

}

.tripcard{
    width: 375px;
    &:hover{
        border: 1px solid #B70000;
        cursor: pointer;
        // box-shadow: 1px 1px 1px 1px #AAA;
    }
    @media (max-width:900px){
        width: 300px;
    }
}

.trip-describtion{
    margin-top: 2vh;
    margin-bottom: 1vh;
    height: auto;
    width: 100%;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(38, 38, 38, 0.75);
    @media (max-width: 900px){
        width: 90vw;
        background: none;
        border: none;
    }
}

.roads-describtion{
    display: flex;
    gap: 2.5%;
    flex-direction: row;
    @media (max-width: 900px){
        flex-direction: column;
        align-items: center;
    }
    margin-bottom: 20px;
    .text-road-desc{
        color: #ccc;
        padding: 2%;
        font-size: 18px;
        width: 600px;
        @media (max-width: 900px){
            width: 300px;
            font-size: 12px;
            // margin-left: 7.5vw;
        }
    }
}

.bike-park-desc{
    color: #ccc;
    padding: 2% 10%; 
    font-size: 18px;
    @media (max-width: 900px){
        padding: 0px  0px !important;
        width: 75vw;
        font-size: 14px;
    }
}

.img-maps{
    width: 500px;
    @media (max-width: 900px){
        width: 300px;
        margin: 20px;
    }
}

.instructors{
    margin: 80px;
    border-top: 1px solid #eee;
    padding: 0px 40px;
    padding-top: 50px;
    @media (max-width: 900px){
        padding: 0px 0px;
        font-size: 18px;
        // padding-top: 20px;
    }
}

.turnus-container {
    background-color: #fefefe;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 60%;
    // margin: auto;
    padding: 20px;
    @media (max-width: 900px){
        max-width: 100%;

        // padding-top: 20px;
    }
}
.turnus-header {
    text-align: center;
    color: #2c3e50;
}
.turnus-item {
    margin: 20px 0;
    padding: 15px;
    background-color: #e7f1ff;
    border-left: 5px solid #3498db;
    border-radius: 4px;
}
.turnus-item h3 {
    margin: 0;
    color: #3498db;
    font-size: 18px;
}
.turnus-item p {
    margin: 5px 0 0;
    color: #7f8c8d;
    font-size: 16px;
}