@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,400&display=swap");

.slide-first {
  background: url("../../../public/images//main.jpg");
  width: 99vw;
  @media (max-width: 900px) {
    width: 100vw;
    background: url("../../../public/images/main_phone.jpg") !important;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
  }
  height: 100vh;
  background-size: 100% 130%;
  position: relative;
  display: flex;
  flex-direction: column;
  .header-first-slide {
    padding-left: 40%;
    font-size: 6.5vh;
    color: #eee;
    font-weight: 800;
    margin-top: 16vh;
    width: 100%;
    line-height: 7.3vh;
    letter-spacing: 0.4vh;
    @media (max-width: 900px) {
      font-size: 4vh;
      width: 100vw;
      text-align: center;
      margin-top: 20vh;
      padding-left: 0%;
    }
  }

  .navs-first-slide {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
    gap: 1.5vw;
    @media (max-width: 900px) {
      margin-top: 31.5vh;
      flex-direction: column;
      // margin-bottom: 15vh;
    }
  }

  .navs {
    color: #fafafa;
    font-size: 3.5vh;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #eee;
      text-shadow: 2px 2px #888;
    }

    @media (max-width: 900px) {
      margin-top: 2vh;
      width: 100vw;
      font-size: 2.25vh;

      text-align: center;
    }
  }

  .logo-container-first-slide-main {
    position: absolute;
    left: 20vw;
    top: 10vh;
    @media (max-width: 900px) {
      // position: relative;
      left: 30vw;
      top: 5vh !important;
    }
    .logo-first-slide-main {
      width: 35vh;
      height: 35vh;

      @media (max-width: 900px) {
        width: 40vw;
        height: 40vw;
        // top: 55vh;
        // left: 15vw;
      }
    }
  }

  .social-container {
    position: absolute;
    top: 10%;
    right: 4%;
    width: 13vw;
    height: 6.5vh;
  }
}

.slide-second {
  padding: 3%;
}

.slide-fifth {
  width: 99vw;
  height: 100vh;
  background-size: 100% 120%;
  display: flex;
  flex-direction: column;
  padding: 0 20vh;
  .fifth-content {
    margin-top: 10vh;
    display: flex;
    gap: 10vh;
  }
}

.img-list-portfolio{
  &:hover{
    border: 2px solid #eee !important;
  }

}

.fourth-slide {
  background: url("../../../public/images/fourthSlideNoLogo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: "Lato", sans-serif;
  font-style: italic;
  height: 100%;
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .iframe-styles {
    width: 37.5vw;
    height: 52.5vh;
    margin: 2vw 0;
    @media (max-width: 900px){
        width: 80vw !important;
        height: 35vh !important;
    }
  }



  .title-content-yt{
    display:flex;
    margin-left:5vw;
    align-items:center;
    position:relative;
    margin-top:2vh;
    justify-content:center;
    @media (max-width: 900px){
        flex-direction: column;
        margin-left: none !important;
      }
  }

  .title-fourth-slide {
    padding: 10px;
    color: rgba(255, 255, 255, 0.95);
    font-size: 5vh;
    font-weight: 700;
    margin-bottom: 2.5vh;
    background: rgba(10, 10, 10, 0.2);
    font-family: "Orbitron", sans-serif;
    @media (max-width: 900px){
        font-size: 2.5vh;
    }

    
    }
    .text-fourth-slide{
        flex:5;
        font-size:2.95vh;
        font-weight:700;
        color: #fafafa;
        padding:25px;      
        @media(max-width: 900px){
            font-size: 1.45vh;
        }
    
  }

  .logo-fourth-slide{
    width: 27vh;
    height: 27vh;
    position: absolute;
    bottom: -10.5vh;
    right: 5.5vh;
    @media (max-width: 900px){
        width: 27vw;
        height: 27vw;
        bottom: -5vh;
        right: 7vw;
    }
}
}

.slide-third {
  height: 92vh;
  width: 99vw;
  display: flex;
  padding: 10vh 7.5vw;
  gap: 3vw;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 2vh;
    align-items: center;
  }
}

.left-content {
  height: 100%;
  width: 100%;
  flex: 4;
  padding: 4.5vh;
  position: relative;

  .left-content-img {
    width: 60%;
    height: 90%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
  }
  .right-content-img {
    width: 60%;
    height: 90%;
    position: absolute;
    top: 10%;
    right: 0px;
    z-index: 2;
  }

  @media (max-width: 900px) {
    // width: 100%;
    // flex: 3;
   
    width: 90%;
    .right-content-img {
      top: 5.5vh;
      width: 45vw !important;
      height: 36vh !important;
      top: 6.5%;
    }

    .left-content-img {
      width: 45vw !important;
      height: 36vh !important;
    }
  }
}

.right-content {
  width: 100%;
  height: 100%;
  flex: 5;
  padding: 2.5vh;
  .title-meet {
    font-size: 5.5vh;
    font-weight: 700;
    color: #b70000;
    font-style: italic;
    @media (max-width: 900px) {
      font-size: 6.5vw;
    //   margin-top: 2vh;
    }
  }
  .title-header {
    font-size: 2.25vh;

    margin-top: 1vh;
    font-weight: 600;
    @media (max-width: 900px) {
      font-size: 2.5vw;
    }
    }
    .bartek-about{
        font-size: 3.25vh;
        color:#fafafa;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-style:italic;
        @media (max-width: 900px){
            font-size: 3.75vw;
        }
  }


  .button-about {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    height: 5.5vh;
    width: 17.5vw;
    font-size: 2vh;
    font-weight: 600;
    background-color: #b70000;
    color: #fafafa;
    text-decoration: none;
    &:hover {
      transition: ease-in-out 0.6s;
      background: #fafafa;
      color: #b70000;
    }

    @media (max-width: 900px){
        width: 75vw;
        // margin-left: 5vw;
        height: 3.75vh;

    }
  }
}

.image-portfolio-main{
  width: 40vw;
  height: 60vh;
  @media (max-width: 900px){
    width: 77.5vw;
    height: 40vh;
  }
}