
.css-z5gn4u-MuiSvgIcon-root{
    display: none !important;
    @media (max-width: 900px){
        display: none !important;
    }
}

.css-kwbfmb{
    display: none !important;
}

.css-19r6kue-MuiContainer-root{
    @media(max-width:900px){
        display: flex !important;
        justify-content: center !important;
    }
}

.nav-main-general{
    background: rgba(10, 10, 10, 0.95) !important;
    border-bottom: 1px solid #fafafa;
    padding-left: 0vh;
    justify-content: flex-start !important;
    @media(max-width: 900px){
        justify-content: center !important;

    }
}

.navbar-main-content{
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    @media (max-width: 900px){
        justify-content: center !important;
        margin: auto !important;
    }
}

.nav-img-logo{
    margin-left: 2vw;
    padding-top: 0.5vh;
    width: 3.5vw;
    height: 3.5vw;
    @media (max-width: 900px){
        margin-left: 0 !important;
        width: 5vw;
        height: 5vw;
  
    }
}

.nav-link-item{
    &:hover{
        background: rgba(10, 10, 10, 0.4) !important;
        text-shadow: 2px 1px #666;

    }
}

.navButton2{
    font-size: 1.5vw !important;
    font-weight: 500 !important;
    font-family: fantasy !important;
    @media (max-width: 900px){
        font-size: 2.5vw !important;
        font-weight: 600 !important;
        font-family: 'Lato', sans-serif !important;

    }
    
}