@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800&display=swap');

html, body, #root, .app, .content{
  height: auto;
  min-height: 100vh;
  width: 100%;
  font-family: 'Orbitron', sans-serif;
  background: rgba(25, 25, 25, 0.95);
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 1vw;
}

::-webkit-scrollbar-track{
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb{
  background: #888;
}

::-webkit-scrollbar-thumb:hover{
  background: #555;
}
