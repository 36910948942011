.content-trainings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12.5vw;
    align-items: flex-start ;
    margin-top: 2vh;
    margin-bottom: 75px;
    background: rgba(25, 25, 25, 0.95) !important;
    font-family: "Lato", sans-serif;
}

.section-styles{
    font-size: 2.5vh !important;
    font-weight: 700 !important;
    @media (max-width: 900px) {
        font-size: 3.75vw !important;
    }
}   

.trainings-skontaktuj{
    font-size: 2vh; 
    margin-bottom: 2vh;
    @media(max-width: 900px){
        font-size: 3vw;
    }
}


.header-trainings{
    font-size: 4.25vh;
    font-weight: 800;
    color: #B70000;
    // font-style: italic;
    @media(max-width: 900px){
        font-size: 6vw;
    }
}

.content-text-trainings{
    font-size: 2.75vh;
    font-family: 'Lato', sans-serif;
    color: #fafafa;
    @media (max-width: 900px){
        font-size: 3.25vw;
    }
}


.trainings-portfolioImages{
    display:flex;
    gap:5vw;
    align-items:center;
    @media (max-width: 900px){
        flex-direction: column;
    }
}


.trainings-title-r4f{
    color: #B70000;
    font-family: 'Orbitron', sans-serif;
}

.trainings-text-r4f{
    color: #fafafa;
    font-family: 'Lato', sans-serif;
    @media (max-width:900px){
        font-size: 3vw;
    }
}

.trainings-main{
    background: rgba(25, 25, 25, 0.95) !important;
    height: 100%;
}

.title-of-trainings{
    color: #B70000;
    font-size: 4.5vh;
    font-style: italic;
    @media (max-width: 900px){
        font-size: 5.5vw;
    }
}

.image-container-trainings{
    display:flex;
    flex-direction:column;
    width: 100%;
    align-items:center;
    justify-content:center;
    @media (max-width: 900px){
        // width: 
    }

    .trainings-image1{
        width: 80%; 
        margin-top: 2vh;
        @media (max-width: 900px){
            width: 80vw;
        }
    }

    .trainings-title-skills{
        margin-top:4vh;
        font-size:3.5vh;
        font-weight:700;
        text-align:center;
        color:#fff;
        margin-bottom:2vh;
        @media(max-width: 900px){
            font-size: 5vw;
        }
    }
    
    .trainings-skill{
        height:5vh;
        font-size:3vh;
        display:flex;
        font-weight:700;
        align-items:center;
        gap:0.75vw;
        font-family: 'Lato', sans-serif;
        @media(max-width: 900px){
            font-size: 3vw;
            margin-top: 3.5vw;
            height: 100%;
            text-align: center;
            align-items: none;
            display: block;
        }
        .icon-trainings{
            color: lightgreen;
            @media(max-width: 900px){
                margin-left: 2vw;
            }
        }
    }

    .trainings-text2{
        font-size:2.75vh;
        text-align:center;
        margin-top:5vh;
        font-family: 'Lato', sens-serif;
        
        @media (max-width: 900px){
            font-size: 4vw;
        }
    }

    .trainings-addInfo{
        @media(max-width: 900px){
            font-size: 2.2vw;
        }
    }
}


.trainings-wyjazdy-main{
    text-align:center;
    font-family:'Lato', sans-serif;
    margin-bottom:5vh;
    font-size:2.75vh;
    @media(max-width: 900px){
        font-size: 3.5vw;
    }
    
}
.trainings-wyjazdy-img{
    width: 66%;
    @media (max-width: 900px){
        width: 95%;
    }
}

.text-of-trainings{
    font-size: 2.75vh;
    color: #ddd;  
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    @media (max-width: 900px){
        font-size: 3.75vw;
    }
}

.button-zapisz{
    width: 50%;
    height: 5vh;
    font-size: 2.25vh !important;
    font-weight: 600 !important;
    color:  #fafafa;
    background:#B70000 !important;
    &:hover{
        color: #B70000 !important;
        background: #fafafa !important;
        transition: ease-in-out 0.4s;
    }
}

.carousel-trainings{
    width: 99vw;
    height: 40vh; 
    margin: 4vh;
    @media (max-width: 900px){
        height: 25vh;
        width: 97vw !important;
        margin: 2vh 0 !important;
    }

    .carousel-cont-img{
        width: 99vw;
        height: 40vh; 
        margin-left: 1.5vw;
        @media(max-width: 900px){
            display: flex;
            justify-content: center;
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 95vw !important;
            margin-left: 0.5vw !important;
            margin-right: 1vw !important;
        }
    }
    .carousel-trainings-item{
        width: 30vw;
        height: 40vh;
        // margin-left: 1.25vw;
        @media (max-width: 900px){
            width: 93vw !important;
            height: 30vh;
            
        }
    }
}

.title-third-tab{
    text-align: left !important;
    width: 100%;
}

.cards-trainings-items{
    display: flex; 
    gap: 1.5vw; 
    min-width: 8vw;
    justify-content: center;
    @media (max-width: 900px) {
        flex-direction: column;
        gap: 1.5vh;
    }
}

.cards-size-trainings{
    width: 50%;
    @media (max-width: 900px) {
        width: 100%;
    }
}