.contact {
  background: rgba(10, 10, 10, 0.1);
  width: 100vw !important;
}

.formComponent-contact {
  width: 99vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
  z-index: 255;

  @media (max-width: 900px){
    margin-top: 3vh;
    z-index: 255;

  }
}

.contact-info {
  width: 100%;
  height: 10vh;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    text-align: center;
    margin-top: 12.5vh;
    font-size: 3vw;
}
}

.text-contact{
  font-size: 22px;
  padding: 10px;
  @media (max-width: 900px){
    font-size: 18px;
  }
}

.contact-content {
  margin: 20px;
  width: 50%;
  @media (max-width: 900px) {
    width: 75%;
  }
}

.logo-first-slide {
  position: absolute;
  top: 5vh;
  right: 1vw;
  width: 35vh;  
  height: 35vh;
  @media (max-width: 900px) {
 
    display: none;
  }
}

.socials {
  position: absolute;
  right: 7.5vw;
  top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  .social-icon-aboutUs {
    // fg-color: #ffffff !important;
    height: 7vh !important;
    width: 7vh !important;
    @media (max-width: 900px) {
      height: 4vh !important;
      width: 4vh !important;
    }
  }
  @media (max-width: 900px) {
    flex-direction: row;
    height: 10vh;
    top: 90%;
    width: 100%;
    right: 0;

  }
}

.contact-napisz{
  padding: 10px;
  text-align: center; 
  margin-top: 0.5vh; 
  z-index: 1;
  @media(max-width: 900px){
    margin-bottom: 10vh;
  }
}

.css-1rv476z-MuiInputBase-input-MuiFilledInput-input{
  @media(max-width: 900px){
    height: 2.75vh !important;
  }
}

.contact-mail{
  text-align: center; 
  z-index: 1;
  @media(max-width: 900px){
    margin-bottom: 10vh;
  }
}

.contact-mail1{
  text-align: center; 
  z-index: 1;
  @media(max-width: 900px){
    margin-bottom: 1vh;
  }
}

.contact-zadzwon{
  text-align: center;
  margin-bottom: 1.75vh;
  @media (max-width: 900px){
    margin-top: 0.5vh;
  }
}

