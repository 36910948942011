.ouroffer-main{
    width: 99vw;
    height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;


    .ouroffer-content{
        background: rgba(10 , 10, 10, 0.35);
        display: flex;
        border: 2px solid #222;
        @media(max-width: 900px){
            display: none;
        }
    }

    

    
}

.react-multi-carousel-list{
    @media (max-width: 900px) {
        width: 95vw !important;
}
}

.ouroffer-content-mobile{
    display: none !important;

    @media (max-width: 900px){
        display: flex !important;
        width: 95vw !important;
        height: 90vh !important; 
        // font-family: fantasy;
        // margin: 4vh;
    }
}

// .mobile-carousel-training-img{
//     @media(max-width: 900px){
//         display: flex;
//         align-items: center;
//         border-bottom: 1px solid #fafafa;
//     }
// }

// .mobile-button-trainings-border{
//     @media(max-width: 900px){
//         border-bottom: 1px solid #fafafa;
//     }
// }

.training-item{
    height:82.5vh;
    width:25vw;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    @media (max-width: 900px){
        height: 70vh !important;
        width: 95vw !important;
        // margin-left: 1.25vw;
        // border-bottom: 1px solid #fafafa !important;
        border-left: 1px solid #fafafa !important;
        border-right: 1px solid #fafafa !important;

        // border-bottom: 1px solid #fafafa;
    }
   
      
    .training-img{
        width:25vw;
        height:32.5vh;
        padding-bottom:0px;
        @media (max-width:900px){
            width: 95vw !important;
            height: 35vh !important;  
        }
    }

    .training-title{
        height:10vh;
        text-align:center;
        font-weight:600;
        letter-spacing:0.15vh;
        margin-top: 1.5vh;
        @media (max-width: 900px){
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    .training-icon-content{
        margin-top:2.5vh;
        width:100%;
        text-align:center;
        
    }

    .training-description{
        display: flex;
        flex-direction:column;
        align-items:center;
        justify-content:flex-end;
        height:30vh;
        margin-bottom:3vh;
        color: #fafafa;
        @media (max-width: 900px){
            // margin-left: 3.5vw;
            height: auto !important;
            border-right: none !important;
        }
    }

    .training-button{
        color:white;
        padding:10px;
        font-weight:600;
        letter-spacing:0.15rem;
        &:hover{
            transition: ease-in-out .6s;
            background: white !important;
            color: #B70000 !important;
        }
    }

    .training-icon{
        margin: 0px 0px; 
        height: 4vh; 
        color: #fafafa;
        @media (max-width: 900px){
            width: 10vw !important;
            height: 10vw !important; 
        }
    }

    
    @media (max-width: 900px){
        width:33.3vw;
        height: 75vh;
        .training-icon{
            height: 4vw; 
            // margin-top: 2vw;

        }
        .training-description{
            // height: 50vh;
            height: auto !important;
            justify-content: space-evenly;
        }

        .training-button{
            padding: 1px !important;
            font-size: 10px;
            height: 3.75vh !important;
            width: 51.5vw !important;
            font-weight: 800;
        }
       
        .training-img{
            width:33.3vw;
            height:20.5vh;
            padding-bottom:0px;       
        }

        .training-title{
            display: flex;
            align-items: center;
            text-align:center;
            font-weight:800;
            letter-spacing:0.15vh;
            font-size: 2vh;
            height: 8vh;
            // margin-bottom: 5vw;
        }
    }
}

.content-training-item{
    padding: 5px 15px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1.75vh;
    font-weight: 600;
    @media (max-width: 900px){
        font-size: 2.75vw;
    }
}

.ourOffer-title{
    font-size: 2.75vh;
    font-style: italic;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    @media (max-width: 900px){
        font-size: 4.5vw;
        // font-weight: 800;
    }
}